import NewReleasesIcon from '@mui/icons-material/NewReleases'
import { Alert, Box, Link } from '@mui/material'
import { Link as Glink } from 'gatsby'
import React from 'react'

const NewFeatureAlert = ({ link, text }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Alert
      icon={<NewReleasesIcon />}
      severity="info"
      sx={{
        maxWidth: 'lg',
        mx: 'auto',
        '& .MuiAlert-message': {
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        },
        backgroundColor: '#e3f2fd',
        color: '#0d47a1',
      }}
    >
      Nowa funkcja!
      <Link
        href={link}
        component={Glink}
        sx={{
          ml: 1,

          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {text}
      </Link>
    </Alert>
  </Box>
)

export default NewFeatureAlert
