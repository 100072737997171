import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LogoutIcon from '@mui/icons-material/Logout'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link, navigate } from 'gatsby'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/authContext'
import DarkModeSwitch from '../DarkModeSwitch'
import { NAV_ITEMS } from '../Routes'

import { getAvatarContent } from '../../../helpers/getAvatarContent'

const RotatingExpandMoreIcon = styled(ExpandMoreIcon)(({ theme, open }) => ({
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
}))

const ACTIVE_COLOR = '#91bff0'

const NavbarMenu = () => {
  const { user, signOut } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenuIndex, setOpenMenuIndex] = useState(null)
  const theme = useTheme()

  const hoverColor = ACTIVE_COLOR

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget)
    setOpenMenuIndex(index)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setOpenMenuIndex(null)
  }

  const handleSignOut = () => {
    signOut()
  }

  const isRouteActive = (path) =>
    window.location.pathname.replaceAll(/[^a-zA-Z0-9]/g, '') === path.replaceAll(/[^a-zA-Z0-9]/g, '')

  const shouldShowLoginButton = !['/zarejestruj', '/logowanie'].some((path) =>
    window.location.pathname.includes(path)
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '64px' }}>
      {NAV_ITEMS.map((item, index) => (
        <React.Fragment key={item.name}>
          {item.innerItems ? (
            <>
              <Button
                onClick={(e) => handleMenuOpen(e, index)}
                endIcon={<RotatingExpandMoreIcon open={openMenuIndex === index} />}
                startIcon={item.icon}
                sx={{
                  borderRadius: 0,
                  color: 'inherit',
                  fontSize: '0.80rem',
                  letterSpacing: '-0.03em',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    color: hoverColor,
                  },
                  mr: 1,
                  borderBottom: item.innerItems.some((category) =>
                    category.items.some((subItem) => isRouteActive(subItem.path))
                  )
                    ? `1px solid ${ACTIVE_COLOR}`
                    : 'none',
                }}
              >
                {item.name}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={openMenuIndex === index}
                onClose={handleMenuClose}
                disableScrollLock
                PaperProps={{
                  elevation: 4,
                  sx: {
                    mr: 3,
                    p: 0,
                  },
                }}
              >
                <Grid container sx={{ px: 3, minWidth: '300px' }}>
                  {item.innerItems &&
                    item.innerItems.map((category) => (
                      <Grid key={category.items[0].path} item xs={item?.innerItems?.length > 1 ? 6 : 12}>
                        {category?.name && (
                          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5, mt: 2 }}>
                            {category.name}
                          </Typography>
                        )}
                        <MenuList>
                          {category.items.map((subItem) => (
                            <MenuItem
                              key={subItem.name}
                              component={Link}
                              to={subItem.path}
                              onClick={handleMenuClose}
                              sx={{
                                fontSize: '14px',
                                backgroundColor: isRouteActive(subItem.path)
                                  ? 'rgba(145, 191, 240, 0.1)'
                                  : 'inherit',
                                color: isRouteActive(subItem.path) ? 'success.main' : 'inherit',
                              }}
                            >
                              <ListItemIcon sx={{ color: 'inherit' }}>{subItem.icon}</ListItemIcon>
                              <ListItemText
                                primary={subItem.name}
                                primaryTypographyProps={{ fontSize: '14px', display: 'inline-block' }}
                              />
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Grid>
                    ))}
                </Grid>
              </Menu>
            </>
          ) : (
            <Button
              component={Link}
              to={item.path}
              startIcon={item.icon}
              sx={{
                color: 'inherit',
                fontSize: '0.85rem',
                letterSpacing: '-0.02em',
                '&.active': { color: ACTIVE_COLOR },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  color: hoverColor,
                },
                mr: 2,
                borderBottom: isRouteActive(item.path) ? `2px solid ${ACTIVE_COLOR}` : 'none',
              }}
            >
              {item.name}
            </Button>
          )}
        </React.Fragment>
      ))}
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          mr: 1,
          borderColor: 'rgba(255, 255, 255, 0.3)',
        }}
      />
      <DarkModeSwitch />
      {shouldShowLoginButton &&
        (user ? (
          <>
            <Box sx={{ ml: 2 }}>
              <Tooltip
                title={`Zalogowano jako ${user.email}`}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: '0.8rem',
                      padding: '8px 12px',
                    },
                  },
                }}
              >
                <Avatar
                  component={Link}
                  to="/profil/"
                  sx={{
                    ml: 7,
                    width: 36,
                    height: 36,
                    cursor: 'pointer',
                    textDecoration: 'none',
                    backgroundColor: theme.palette.success.main,
                    color: '#ffffff',
                    '&:hover': {
                      opacity: 0.8,
                    },
                    fontSize: '1rem',
                  }}
                >
                  {getAvatarContent(user)}
                </Avatar>
              </Tooltip>
            </Box>
            <Button
              onClick={handleSignOut}
              startIcon={<LogoutIcon />}
              variant="outlined"
              color="inherit"
              sx={{
                ml: 2,
                fontSize: '0.75rem',
                letterSpacing: '-0.02em',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  color: hoverColor,
                },
              }}
            >
              Wyloguj
            </Button>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: 2, ml: 7 }}>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => navigate('/logowanie/')}
              sx={{
                fontSize: '0.85rem',
                letterSpacing: '-0.02em',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  color: hoverColor,
                },
              }}
            >
              Zaloguj
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => navigate('/zarejestruj/')}
              sx={{
                fontSize: '0.85rem',
                letterSpacing: '-0.02em',
              }}
            >
              Załóż konto
            </Button>
          </Box>
        ))}
    </Box>
  )
}

export default NavbarMenu
