import React from 'react'
import HomeIcon from '@mui/icons-material/Home'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import GasMeterIcon from '@mui/icons-material/GasMeter'
import Water from '@mui/icons-material/Water'
import AirIcon from '@mui/icons-material/Air'
import CalculateIcon from '@mui/icons-material/Calculate'
import SchoolIcon from '@mui/icons-material/School'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import StraightenIcon from '@mui/icons-material/Straighten'
import CompressIcon from '@mui/icons-material/Compress'
import WavesIcon from '@mui/icons-material/Waves'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import EngineeringIcon from '@mui/icons-material/Engineering'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import TransformIcon from '@mui/icons-material/Transform'
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull'
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import NearbyErrorIcon from '@mui/icons-material/NearbyError'
import PropaneTankIcon from '@mui/icons-material/PropaneTank'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import HvacIcon from '@mui/icons-material/Hvac'
import ScaleIcon from '@mui/icons-material/Scale'
import ShowerIcon from '@mui/icons-material/Shower'
import WaterDamageIcon from '@mui/icons-material/WaterDamage'
import FilePresentIcon from '@mui/icons-material/FilePresent'

export const LAYOUTS = {
  CONVERTERS: 'converter',
  HEATING: 'heating',
  WATER_SEWAGE: 'water-sewage',
  VENT: 'ventilation',
}

const CONVERTER_PATH = '/konwerter-jednostek'
const HEATING_PATH = '/ogrzewanie'
const WATER_SEWAGE_PATH = '/wod-kan'
const VENT_PATH = '/wentylacja'
const GAS_PATH = '/gaz'
const FILES_PATH = '/pliki-dwg'

export const CONVERTER_ROUTES = [
  { path: `${CONVERTER_PATH}/cisnienie/`, name: 'Ciśnienie', icon: <CompressIcon /> },
  { path: `${CONVERTER_PATH}/objetosc/`, name: 'Objętość', icon: <CalculateIcon /> },
  { path: `${CONVERTER_PATH}/dlugosc/`, name: 'Długość', icon: <StraightenIcon /> },
  { path: `${CONVERTER_PATH}/energia/`, name: 'Energia', icon: <LocalFireDepartmentIcon /> },
  { path: `${CONVERTER_PATH}/pole-powierzchni/`, name: 'Powierzchnia', icon: <CalculateIcon /> },
  { path: `${CONVERTER_PATH}/predkosc/`, name: 'Prędkość', icon: <SwapHorizIcon /> },
  { path: `${CONVERTER_PATH}/masa/`, name: 'Masa', icon: <EngineeringIcon /> },
  { path: `${CONVERTER_PATH}/gestosc/`, name: 'Gęstość', icon: <EngineeringIcon /> },
]

export const HEATING_ROUTES = [
  { path: `${HEATING_PATH}/srednice-przewodow/`, name: 'Przewody', icon: <DragHandleIcon /> },
  { path: `${HEATING_PATH}/autorytet-zaworu/`, name: 'Autorytet zaworu', icon: <NearbyErrorIcon /> },
  {
    path: `${HEATING_PATH}/dobor-naczynia-przeponowego/`,
    name: 'Naczynie przeponowe',
    icon: <PropaneTankIcon />,
  },
]

export const WATER_SEWAGE_ROUTES = [
  {
    path: `${WATER_SEWAGE_PATH}/srednice-przewodow-wodociagowych`,
    name: 'Przewody Wod.',
    icon: <WaterDamageIcon />,
  },
  { path: `${WATER_SEWAGE_PATH}/kalkulator-kanalizacji/`, name: 'Kanalizacja', icon: <ShowerIcon /> },
  {
    path: `${WATER_SEWAGE_PATH}/kalkulator-przylacza-i-sieci-wodociagowej/`,
    name: 'Sieć i przyłącze wod.',
    icon: <Water />,
  },
]

export const VENT_ROUTES = [
  { path: `${VENT_PATH}/dobor-kanalow-wentylacyjnych/`, name: 'Kanały wentylacyjne', icon: <AirIcon /> },
  { path: `${VENT_PATH}/kalkulator-odzysku-ciepla/`, name: 'Odzysk ciepła', icon: <ShuffleIcon /> },
  {
    path: `${VENT_PATH}/kalkulator-strumienia-powietrza-i-temperatury/`,
    name: 'Strumień powietrza - temperatury',
    icon: <AirIcon />,
  },
  {
    path: `${VENT_PATH}/dobor-mocy-chlodnicy-entalpia-punkt-rosy/`,
    name: 'Dobór mocy chłodnicy - Entalpia - Punkt Rosy',
    icon: <HvacIcon />,
  },
  {
    path: `${VENT_PATH}/kalkulator-ciezaru-kanalow-wentylacyjnych/`,
    name: 'Ciężar kanałów wentylacyjnych',
    icon: <ScaleIcon />,
  },
]
export const NAV_ITEMS = [
  {
    name: 'Instalacje Sanitarne',
    icon: <EngineeringIcon />,
    innerItems: [
      {
        name: 'Ogrzewanie',
        icon: <LocalFireDepartmentIcon />,
        items: HEATING_ROUTES,
      },
      {
        name: 'Wod-Kan',
        icon: <Water />,
        items: WATER_SEWAGE_ROUTES,
      },
      {
        name: 'Wentylacja',
        icon: <AirIcon />,
        items: VENT_ROUTES,
      },
      {
        name: 'Gaz',
        icon: <GasMeterIcon />,
        items: [{ path: `${GAS_PATH}/`, name: 'Dobór średnicy', icon: <GasMeterIcon /> }],
      },
      {
        name: 'Pliki dwg',
        icon: <FilePresentIcon />,
        items: [{ path: `${FILES_PATH}/`, name: 'Pliki dwg', icon: <FilePresentIcon /> }],
      },
    ],
  },
  // {
  //   name: 'Elektryka',
  //   icon: <ElectricBoltIcon />,
  //   innerItems: [
  //     {
  //       name: '',
  //       icon: <ElectricBoltIcon />,
  //       items: [
  //         { path: '/elektryka/oswietlenie/', name: 'Oświetlenie', icon: <LightbulbIcon /> },
  //         { path: '/elektryka/transformatory/', name: 'Transformatory', icon: <TransformIcon /> },
  //         { path: '/elektryka/kondensatory/', name: 'Kondensatory', icon: <BatteryChargingFullIcon /> },
  //         { path: '/elektryka/przekazniki/', name: 'Przekaźniki', icon: <SettingsInputComponentIcon /> },
  //         { path: '/elektryka/przetwornice/', name: 'Przetwornice', icon: <SettingsEthernetIcon /> },
  //       ],
  //     },
  //   ],
  // },
  {
    name: 'Inne',
    icon: <CalculateIcon />,
    innerItems: [
      {
        name: '',
        icon: <CalculateIcon />,
        items: [
          { path: '/konwerter-jednostek/', name: 'Przelicznik jednostek', icon: <SwapHorizIcon /> },
          { path: '/pojemnosc-rur/', name: 'Pojemność rur', icon: <StraightenIcon /> },
          { path: '/sprezone-powietrze/', name: 'Sprężone powietrze', icon: <CompressIcon /> },
          {
            path: '/natezenie-przeplywu-deszczowki/',
            name: 'Natężenie przepływu deszczówki',
            icon: <WavesIcon />,
          },
          {
            path: '/kompensacja-wydluzen-termicznych/',
            name: 'Kompensacja wydłużeń termicznych',
            icon: <LinearScaleIcon />,
          },
        ],
      },
    ],
  },

  { name: 'Wiedza', path: '/wiedza/', icon: <SchoolIcon /> },
]
